import React from 'react';
import { makeStyles } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';
import content from '../../../content/careers/intro.json';
import Colors from '../../../styles/colors';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Dimensions from '../../../styles/dimensions';

const Intro = () => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<StaticImage
				src="../../../images/careers/banner.jpg"
				alt=""
				className={classes.image}
				layout="constrained"
				loading="eager"
				placeholder="blurred"
				objectFit="cover"
				objectPosition="center center"
			/>

			<div className={classes.contentContainer}>
				<h1 className={classes.header}>
					<span>{content.titlePart1}</span>
					<span className={classes.headerPart2}>{content.titlePart2}</span>
				</h1>

				{content.paragraphs.map((item, key) => (
					<p key={key} className={classes.paragraph}>
						{item}
					</p>
				))}
			</div>
		</section>
	);
};

export default Intro;

const useClasses = makeStyles({
	section: {
		backgroundColor: Colors.pageBackgroundBlack,
		paddingBottom: '80px',
	},
	image: {
		minHeight: '300px',
	},
	contentContainer: {
		paddingLeft: '16px',
		paddingRight: '16px',
	},
	header: {
		color: Colors.white,
		textAlign: 'center',
		display: 'flex',
		flexDirection: 'column',
		marginTop: '120px',
		marginBottom: '48px',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallTitle.fontSize,
			lineHeight: Dimensions.smallTitle.lineHeight,
		},
	},
	headerPart2: {
		color: Colors.limeGreen,
	},
	paragraph: {
		textAlign: 'center',
		color: Colors.whiteDimmed,
		whiteSpace: 'pre-line',
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		margin: '48px 0',
		'&:last-of-type': {
			marginBottom: 0,
		},
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallText.fontSize,
			lineHeight: Dimensions.smallText.lineHeight,
		},
	},
});
