import React from 'react';
import Layout from '../components/layout';
import Careers from '../components/sections/careers/careers';
import Contact from '../components/sections/careers/contact';
import Intro from '../components/sections/careers/intro';
import SEO from '../components/seo';

const CareersPage = () => {
	const handleLinkClick = React.useCallback((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		if (typeof window !== 'undefined') {
			document.getElementById('___gatsby')?.scroll({ top: 0, behavior: 'auto' });
		}
	}, []);

	return (
		<Layout>
			<SEO title="Karriere" />

			<Intro />
			<Careers />
			<Contact handleLinkClick={handleLinkClick} />
		</Layout>
	);
};
export default CareersPage;
