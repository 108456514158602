import React from 'react';
import { Collapse, Grid, makeStyles } from '@material-ui/core';
import Colors from '../../../styles/colors';
import content from '../../../content/careers/careers.json';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Dimensions from '../../../styles/dimensions';
import { StaticImage } from 'gatsby-plugin-image';
import Fjalla from '../../../fonts/Fjalla_One/fjalla';

const Careers = () => {
	const [traineesExpanded, setTraineesExpanded] = React.useState(false);
	const [studentsExpanded, setStudentsExpanded] = React.useState(false);
	const [managementExpanded, setManagementExpanded] = React.useState(false);
	const [trainersExpanded, setTrainersExpanded] = React.useState(false);

	const classes = useClasses();

	return (
		<div className={classes.gridContainer}>
			<Grid container classes={{ container: classes.grid }} spacing={3}>
				<Grid item xs={12} md={6}>
					<section className={classes.section}>
						<h3 className={classes.offerHeader}>{content.offers.trainees.title}</h3>
						{content.offers.trainees.paragraphs.map((item, key) => (
							<p key={key} className={classes.offerTextElement}>
								{item}
							</p>
						))}

						<button
							className={classes.expandButton}
							onClick={(event) => {
								const target = event.target as HTMLButtonElement;
								target.classList.add(classes.hidden);
								setTraineesExpanded(true);
							}}>
							{content.expandButtonText}
						</button>

						<Collapse in={traineesExpanded}>
							<h4 className={classes.offerSubheader}>{content.offers.trainees.requirements.title}</h4>
							<p className={classes.offerTextElement}>
								{content.offers.trainees.requirements.paragraph1}
							</p>
							<ul className={classes.offerTextElement}>
								{content.offers.trainees.requirements.list.map((item, key) => (
									<li key={key}>{item}</li>
								))}
							</ul>
							<p className={classes.offerTextElement}>
								{content.offers.trainees.requirements.paragraph2}
							</p>

							<h4 className={classes.offerSubheader}>{content.offers.trainees.instructor.title}</h4>
							{content.offers.trainees.instructor.paragraphs.map((item, key) => (
								<p key={key} className={classes.offerTextElement}>
									{item}
								</p>
							))}
							<ul className={classes.offerTextElement}>
								{content.offers.trainees.instructor.list.map((item, key) => (
									<li key={key}>{item}</li>
								))}
							</ul>

							<a
								href={`mailto:${content.referent.email}?subject=${content.offers.trainees.title}`}
								className={classes.buttonLink}>
								{content.applyButtonText}
							</a>
						</Collapse>
					</section>

					<section className={classes.section}>
						<h3 className={classes.offerHeader}>{content.offers.students.title}</h3>
						{content.offers.students.paragraphs.map((item, key) => (
							<p key={key} className={classes.offerTextElement}>
								{item}
							</p>
						))}

						<button
							className={classes.expandButton}
							onClick={(event) => {
								const target = event.target as HTMLButtonElement;
								target.classList.add(classes.hidden);
								setStudentsExpanded(true);
							}}>
							{content.expandButtonText}
						</button>

						<Collapse in={studentsExpanded}>
							<h4 className={classes.offerSubheader}>{content.offers.students.degree.title}</h4>
							{content.offers.students.degree.paragraphs.map((item, key) => (
								<p key={key} className={classes.offerTextElement}>
									{item}
								</p>
							))}

							<h4 className={classes.offerSubheader}>{content.offers.students.activity.title}</h4>
							{content.offers.students.activity.paragraphs.map((item, key) => (
								<p key={key} className={classes.offerTextElement}>
									{item}
								</p>
							))}
							<ul className={classes.offerTextElement}>
								{content.offers.students.activity.list1.map((item, key) => (
									<li key={key}>{item}</li>
								))}
							</ul>
							<p className={classes.offerTextElement}>{content.offers.students.activity.paragraph1}</p>
							<ul className={classes.offerTextElement}>
								{content.offers.students.activity.list2.map((item, key) => (
									<li key={key}>{item}</li>
								))}
							</ul>
							<p className={classes.offerTextElement}>{content.offers.students.activity.paragraph2}</p>

							<h4 className={classes.offerSubheader}>{content.offers.students.advantages.title}</h4>
							{content.offers.students.advantages.paragraphs.map((item, key) => (
								<p key={key} className={classes.offerTextElement}>
									{item}
								</p>
							))}
							<ul className={classes.offerTextElement}>
								{content.offers.students.advantages.list.map((item, key) => (
									<li key={key}>{item}</li>
								))}
							</ul>

							<h4 className={classes.offerSubheader}>{content.offers.students.instructor.title}</h4>
							{content.offers.students.instructor.paragraphs.map((item, key) => (
								<p key={key} className={classes.offerTextElement}>
									{item}
								</p>
							))}

							<h4 className={classes.offerSubheader}>{content.offers.students.prospects.title}</h4>
							<p className={classes.offerTextElement}>{content.offers.students.prospects.paragraph1}</p>
							<ul className={classes.offerTextElement}>
								{content.offers.students.prospects.list.map((item, key) => (
									<li key={key}>{item}</li>
								))}
							</ul>
							<p className={classes.offerTextElement}>{content.offers.students.prospects.paragraph2}</p>

							<a
								href={`mailto:${content.referent.email}?subject=${content.offers.students.title}`}
								className={classes.buttonLink}>
								{content.applyButtonText}
							</a>
						</Collapse>
					</section>

					<section className={classes.section}>
						<h3 className={classes.offerHeader}>{content.offers.management.title}</h3>
						{content.offers.management.paragraphs.map((item, key) => (
							<p key={key} className={classes.offerTextElement}>
								{item}
							</p>
						))}

						<button
							className={classes.expandButton}
							onClick={(event) => {
								const target = event.target as HTMLButtonElement;
								target.classList.add(classes.hidden);
								setManagementExpanded(true);
							}}>
							{content.expandButtonText}
						</button>

						<Collapse in={managementExpanded}>
							{content.offers.management.rest.map((part, i) => (
								<React.Fragment key={i}>
									<p className={classes.offerTextElement}>{part.paragraph}</p>
									<ul className={classes.offerTextElement}>
										{part.list.map((item, key) => (
											<li key={key}>{item}</li>
										))}
									</ul>
								</React.Fragment>
							))}
							<a
								href={`mailto:${content.referent.email}?subject=${content.offers.management.title}`}
								className={classes.buttonLink}>
								{content.applyButtonText}
							</a>
						</Collapse>
					</section>

					<section className={classes.section}>
						<h3 className={classes.offerHeader}>{content.offers.trainers.title}</h3>
						{content.offers.trainers.paragraphs.map((item, key) => (
							<p key={key} className={classes.offerTextElement}>
								{item}
							</p>
						))}

						<button
							className={classes.expandButton}
							onClick={(event) => {
								const target = event.target as HTMLButtonElement;
								target.classList.add(classes.hidden);
								setTrainersExpanded(true);
							}}>
							{content.expandButtonText}
						</button>

						<Collapse in={trainersExpanded}>
							<h4 className={classes.offerSubheader}>{content.offers.trainers.areas.title}</h4>
							{content.offers.trainers.areas.paragraphs1.map((item, key) => (
								<p key={key} className={classes.offerTextElement}>
									{item}
								</p>
							))}
							<ul className={classes.offerTextElement}>
								{content.offers.trainers.areas.list.map((item, key) => (
									<li key={key}>{item}</li>
								))}
							</ul>
							{content.offers.trainers.areas.paragraphs2.map((item, key) => (
								<p key={key} className={classes.offerTextElement}>
									{item}
								</p>
							))}

							<h4 className={classes.offerSubheader}>{content.offers.trainers.employer.title}</h4>
							{content.offers.trainers.employer.paragraphs.map((item, key) => (
								<p key={key} className={classes.offerTextElement}>
									{item}
								</p>
							))}

							<a
								href={`mailto:${content.referent.email}?subject=${content.offers.trainers.title}`}
								className={classes.buttonLink}>
								{content.applyButtonText}
							</a>
						</Collapse>
					</section>

					<section className={classes.section}>
						<h3 className={classes.offerHeader}>{content.offers.temporary.title}</h3>
						<p className={classes.offerTextElement}>{content.offers.temporary.paragraph}</p>

						<a
							href={`mailto:${content.referent.email}?subject=${content.offers.temporary.title}`}
							className={classes.buttonLink}>
							{content.applyButtonText}
						</a>
					</section>
				</Grid>

				<Grid item xs={12} md={6}>
					<section className={classes.section}>
						<article className={classes.initiativeApplicationContainer}>
							<h2 className={classes.asideHeader}>{content.initiativeApplications.title}</h2>

							<hr className={classes.divider} />

							{content.initiativeApplications.paragraphs.map((item, key) => (
								<p key={key} className={classes.asideParagraph}>
									{item}
								</p>
							))}
						</article>

						<article>
							<h2 className={classes.asideHeader}>{content.howToApply.title}</h2>

							<hr className={classes.divider} />

							<p className={classes.asideParagraph}>{content.howToApply.firstParagraph}</p>

							<ul className={classes.asideList}>
								{content.howToApply.list.map((item, key) => (
									<li key={key}>{item}</li>
								))}
							</ul>

							{content.howToApply.paragraphs.map((item, key) => (
								<p key={key} className={classes.asideParagraph}>
									{item}
								</p>
							))}
						</article>
					</section>

					<section className={`${classes.section} ${classes.referentSection}`}>
						<div className={classes.referentContainer}>
							<div>
								<h3 className={classes.referentName}>{content.referent.name}</h3>
								<p className={classes.referentPosition}>{content.referent.position}</p>
							</div>
							<StaticImage
								src="../../../images/careers/thorben.jpg"
								alt="Thorben"
								layout="constrained"
								loading="lazy"
								placeholder="blurred"
								quality={100}
								className={classes.referentImage}
							/>
						</div>

						<hr className={classes.divider} />

						<p className={classes.referentDetails}>
							<span className={classes.referentDetailsKeyword}>{content.referent.emailText}</span>{' '}
							<a href={`mailto:${content.referent.email}`} className={classes.referentDetailsData}>
								{content.referent.email}
							</a>
						</p>
					</section>
				</Grid>
			</Grid>
		</div>
	);
};

export default Careers;

const useClasses = makeStyles({
	gridContainer: {
		backgroundColor: Colors.offerBackgroundBlack,
	},
	grid: {
		width: '100%',
		maxWidth: '1296px',
		margin: '0 auto',
		padding: '48px 60px',
		'@media (min-width: 960px) and (max-width: 1199px)': {
			padding: '32px 20px',
		},
		'@media (max-width: 599px)': {
			padding: '32px 4px',
		},
	},
	section: {
		backgroundColor: Colors.pageBackgroundBlack,
		borderRadius: '10px',
		padding: '48px',
		marginBottom: '24px',
		'&:last-of-type': {
			marginBottom: 0,
		},
	},
	divider: {
		border: 0,
		borderTop: `4px solid ${Colors.limeGreen}`,
	},
	initiativeApplicationContainer: {
		marginBottom: '88px',
	},
	asideHeader: {
		color: Colors.white,
		marginTop: 0,
		marginBottom: '24px',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallTitle.fontSize,
			lineHeight: Dimensions.smallTitle.lineHeight,
		},
	},
	asideParagraph: {
		color: Colors.whiteDimmed,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		margin: '24px 0',
		'&:last-of-type': {
			marginBottom: 0,
		},
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallText.fontSize,
			lineHeight: Dimensions.smallText.lineHeight,
		},
	},
	asideList: {
		color: Colors.white,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		margin: '24px 0',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallText.fontSize,
			lineHeight: Dimensions.smallText.lineHeight,
		},
	},
	referentSection: {
		'@media (min-width: 960px)': {
			position: 'sticky',
			top: 'calc(6.25em + 24px)',
		},
	},
	referentContainer: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		marginBottom: '24px',
	},
	referentName: {
		color: Colors.white,
		fontSize: Dimensions.regularTitle.fontSize,
		lineHeight: Dimensions.regularTitle.lineHeight,
		marginTop: 0,
		marginBottom: '12px',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallTitle.fontSize,
			lineHeight: Dimensions.smallTitle.lineHeight,
		},
	},
	referentPosition: {
		color: Colors.limeGreen,
		fontFamily: Fjalla.regular,
		fontSize: Dimensions.largeText.fontSize,
		lineHeight: Dimensions.largeText.lineHeight,
		whiteSpace: 'pre-line',
		marginTop: '12px',
		marginBottom: 0,
		'@media (max-width: 599px)': {
			fontSize: Dimensions.regularText.fontSize,
			lineHeight: Dimensions.regularText.lineHeight,
		},
	},
	referentImage: {
		borderRadius: '50%',
		height: '160px',
		width: '160px',
		'@media (max-width: 599px)': {
			display: 'none !important',
		},
	},
	referentDetails: {
		color: Colors.whiteDimmed,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.largeText.fontSize,
		lineHeight: Dimensions.largeText.lineHeight,
		'&:last-of-type': {
			marginBottom: 0,
		},
		'@media (max-width: 599px)': {
			fontSize: Dimensions.regularText.fontSize,
			lineHeight: Dimensions.regularText.lineHeight,
		},
	},
	referentDetailsKeyword: {
		color: Colors.white,
		fontFamily: FiraSans.semiBold,
	},
	referentDetailsData: {
		transition: 'color 150ms',
		'&::hover': {
			color: Colors.white,
		},
	},
	expandButton: {
		display: 'inline-block',
		borderRadius: '8px',
		border: 0,
		outline: 0,
		padding: '14px',
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		cursor: 'pointer',
		marginTop: '48px',
	},
	hidden: {
		display: 'none',
	},
	buttonLink: {
		display: 'inline-block',
		borderRadius: '8px',
		padding: '14px',
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginTop: '48px',
	},
	offerHeader: {
		color: Colors.white,
		fontSize: Dimensions.regularTitle.fontSize,
		lineHeight: Dimensions.regularTitle.lineHeight,
		marginTop: 0,
		marginBottom: '48px',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallTitle.fontSize,
			lineHeight: Dimensions.smallTitle.lineHeight,
		},
	},
	offerSubheader: {
		color: Colors.white,
		fontSize: Dimensions.largeText.fontSize,
		lineHeight: Dimensions.largeText.lineHeight,
		marginTop: '48px',
		marginBottom: '12px',
		'@media (max-width: 599px)': {
			fontSize: Dimensions.regularText.fontSize,
			lineHeight: Dimensions.regularText.lineHeight,
		},
	},
	offerTextElement: {
		color: Colors.whiteDimmed,
		fontFamily: FiraSans.book,
		fontSize: Dimensions.regularText.fontSize,
		lineHeight: Dimensions.regularText.lineHeight,
		margin: '24px 0',
		whiteSpace: 'pre-line',
		'&:last-of-type': {
			marginBottom: 0,
		},
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallText.fontSize,
			lineHeight: Dimensions.smallText.lineHeight,
		},
	},
});
