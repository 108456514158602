import React from 'react';
import { makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';
import Colors from '../../../styles/colors';
import content from '../../../content/careers/contact.json';
import FiraSans from '../../../fonts/Fira_Sans/firaSans';
import Dimensions from '../../../styles/dimensions';

interface Props {
	handleLinkClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

const Contact = ({ handleLinkClick }: Props) => {
	const classes = useClasses();

	return (
		<section className={classes.section}>
			<h2 className={classes.header}>{content.title}</h2>
			{/* 
			<Link to={content.button.to} className={classes.link} onClick={handleLinkClick}>{content.button.text}</Link> */}

			<a
				href={`mailto:${content.referent.email}?subject=${content.offers.management.title}`}
				className={classes.buttonLink}>
				{content.button.text}
			</a>
		</section>
	);
};

export default Contact;

const useClasses = makeStyles({
	section: {
		backgroundColor: Colors.pageBackgroundBlack,
		padding: '72px 16px',
		textAlign: 'center',
	},
	header: {
		color: Colors.white,
		'@media (max-width: 599px)': {
			fontSize: Dimensions.smallTitle.fontSize,
			lineHeight: Dimensions.smallTitle.lineHeight,
		},
	},
	link: {
		display: 'inline-block',
		borderRadius: '8px',
		padding: '0.875em',
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
	},
	buttonLink: {
		display: 'inline-block',
		borderRadius: '8px',
		padding: '14px',
		backgroundColor: Colors.limeGreen,
		color: Colors.textBlack,
		fontFamily: FiraSans.medium,
		fontSize: Dimensions.smallText.fontSize,
		lineHeight: Dimensions.smallText.lineHeight,
		marginTop: '48px',
	},
});
